import './scss/index.scss'
import * as THREE from 'three'
import galaxyVertexShader from './shaders/galaxy/vertex.glsl'
import galaxyFragmentShader from './shaders/galaxy/fragment.glsl'
import gsap from 'gsap'
import * as CANNON from 'cannon'

THREE.ColorManagement.enabled = false

//Define scroll Percent
let scrollPercent = 0
document.body.onscroll = () => {
    //calculate the current scroll progress as a percentage
    scrollPercent = ((document.documentElement.scrollTop || document.body.scrollTop) / ((document.documentElement.scrollHeight || document.body.scrollHeight) - document.documentElement.clientHeight)) * 100
}

/**
 * Loaders
 */

const loaderContainer = document.querySelector(".loader-container")

let progressRatio = 0
while (progressRatio < 1.5) {
    progressRatio += 0.1
}

//Rotated lines 
let loadingBarElements = document.getElementsByClassName('loading-bar')

gsap.delayedCall(1.4, () => {
    for (let i = 0; i < loadingBarElements.length; i++) {
        loadingBarElements[i].style.transform = `scaleX(${progressRatio})`
        gsap.delayedCall(0.5, () => {
            loadingBarElements[i].style.transform += `rotate(${i * 15}deg)`
        })
    }
})

/**
 * Menu
 */
let mousemoveEvent = null
const cursor = {}
cursor.x = 0
cursor.y = 0

//Displaying/undisplaying Hamburger Menu 
const hamburger = document.querySelector('.hamburger')
const menu = document.querySelector('.menu')
const mainContainer = document.querySelector('.main-container')

function deleteMousemoveEvent() {
    if (!mousemoveEvent)
        return
    window.removeEventListener('mousemove', mousemoveEvent);
    mousemoveEvent = null;
}
function addMouseMoveEvent() {
    if (mousemoveEvent)
        return
    mousemoveEvent = (event) => {
        cursor.x = event.clientX / sizes.width - 0.5;
        cursor.y = event.clientY / sizes.height - 0.5;
    };
    window.addEventListener('mousemove', mousemoveEvent);
}
function displayMenuHamburger() {
    mainContainer.classList.toggle('display-none')
    menu.classList.toggle('opacity-reverse')
    if (menu.classList.contains('is-active')) {
        gsap.delayedCall(1, () => {
            menu.classList.toggle("is-active")
            hamburger.classList.toggle("is-activeM")
            deleteMousemoveEvent()
        })
    }
    if (menu.classList.contains('is-active'))
        return
    menu.classList.toggle("is-active")
    hamburger.classList.toggle("is-activeM")
    addMouseMoveEvent()
}
//Display burger menu on click
hamburger.addEventListener("click", () => {
    displayMenuHamburger()
})
//Undisplay burger onclick on li
for (const child of menu.children) {
    child.addEventListener("click", () => {
        displayMenuHamburger()
    })
}

//Nav Buttons Menu
const homeBtns = document.querySelectorAll(".home-button")
const portBtns = document.querySelectorAll(".projects-button")
const aboutBtns = document.querySelectorAll('.about-button')


/**
 * Container sections
 */
const containerSectionMaestrom = document.querySelector('.container')

/**
 * Base
 */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

//background color
const sceneBackgroundColor = new THREE.Color(0x00010E)
scene.background = sceneBackgroundColor

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms: {
        uAlpha: { value: 1.0 },
        uColor: { value: sceneBackgroundColor }
    },
    vertexShader: `
        void main() {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;
        uniform vec3 uColor;

        void main() {
            gl_FragColor = vec4(uColor, uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

/**
 * Portal Black Hole
 */
const parameters = {}
parameters.count = 70000
parameters.size = 0.2
parameters.radius = 5
parameters.branches = 3
parameters.spin = 0
parameters.randomness = 1.6
parameters.randomnessPower = 1.9
parameters.insideColor = '#ff6030'
parameters.outsideColor = '#3c5eb4'

let geometry = null
let material = null
let points = null

const generateGalaxy = () => {
    if (points !== null) {
        geometry.dispose()
        material.dispose()
        scene.remove(points)
    }

    /**
     * Geometry
     */
    geometry = new THREE.BufferGeometry()

    const positions = new Float32Array(parameters.count * 3)
    const colors = new Float32Array(parameters.count * 3)
    const scales = new Float32Array(parameters.count * 1)

    const insideColor = new THREE.Color(parameters.insideColor)
    const outsideColor = new THREE.Color(parameters.outsideColor)

    for (let i = 0; i < parameters.count; i++) {
        const i3 = i * 3

        // Position
        const radius = Math.random() * parameters.radius

        const branchAngle = (i % parameters.branches) / parameters.branches * Math.PI * 2

        const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius
        const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius
        const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius

        positions[i3] = Math.cos(branchAngle) * radius + randomX
        positions[i3 + 1] = randomY
        positions[i3 + 2] = Math.sin(branchAngle) * radius + randomZ

        // Color
        const mixedColor = insideColor.clone()
        mixedColor.lerp(outsideColor, radius / parameters.radius)

        colors[i3] = mixedColor.r
        colors[i3 + 1] = mixedColor.g
        colors[i3 + 2] = mixedColor.b

        //Scale 
        scales[i] = Math.random()

    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
    geometry.setAttribute('aScale', new THREE.BufferAttribute(colors, 1))

    /**
     * Material
     */
    material = new THREE.ShaderMaterial({
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        vertexColors: true,
        vertexShader: galaxyVertexShader,
        fragmentShader: galaxyFragmentShader,
        uniforms: {
            uSize: { value: 20 * renderer.getPixelRatio() },
            uTime: { value: 0 }
        }
    })

    /**
     * Points
     */
    points = new THREE.Points(geometry, material)
    scene.add(points)
}

/**
 * Light
 */

const LightSphere = new THREE.PointLight(0xff698e, 0.5);
LightSphere.position.set(0, 0, 0)

const ambientLight = new THREE.AmbientLight(0xff698e, 0.3)
scene.add(LightSphere, ambientLight)


/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

const sphereDispTexture = textureLoader.load('/assets/textures/sphere/red_sand_disp_1k.png')
const sphereNormalTexture = textureLoader.load('/assets/textures/sphere/red_sand_nor_gl_1k.jpg')

/**
 * Black Sphere
 */

const sphereGeometry = new THREE.SphereGeometry(0.1, 36, 24);
const sphereSmallGeometry = new THREE.SphereGeometry(0.06, 36, 24);
const sphereFatGeometry = new THREE.SphereGeometry(0.12, 36, 24);
const sphereMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    displacementMap: sphereDispTexture,
    displacementScale: 0.01,
    normalMap: sphereNormalTexture,
    roughness: 0.5,
    metalness: 0.5,
})

const sphereOne = new THREE.Mesh(sphereFatGeometry, sphereMaterial)
const sphereTwo = new THREE.Mesh(sphereSmallGeometry, sphereMaterial)
const sphereThree = new THREE.Mesh(sphereGeometry, sphereMaterial)

const sphereGroup = new THREE.Group()
sphereGroup.add(sphereOne, sphereTwo, sphereThree)

scene.add(sphereGroup)

/**
 * Physics
 */

//World
const world = new CANNON.World()


//Material
const defaultMaterial = new CANNON.Material('default')
const defaultContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial,
    {
        friction: 1,
        restitution: 0,
    }
)

world.addContactMaterial(defaultContactMaterial)
world.defaultContactMaterial = defaultContactMaterial

//Creation sphere physics

function createSphereBody(world, radius, position, mass, material, sleepSpeedLimit) {
    const shape = new CANNON.Sphere(radius);
    const body = new CANNON.Body({
        mass: mass,
        shape: shape,
        position: position,
        material: material,
        sleepSpeedLimit: sleepSpeedLimit,
    });

    world.addBody(body);

    return body;
}

// Utilisation
const sphereOneBody = createSphereBody(world, 0.12, new CANNON.Vec3(0, 5, 0.4), 5, defaultContactMaterial, 0.02);
const sphereTwoBody = createSphereBody(world, 0.06, new CANNON.Vec3(0.35, 5, -0.2), 5, defaultContactMaterial, 0.02);
const sphereThreeBody = createSphereBody(world, 0.1, new CANNON.Vec3(-0.35, 5, -0.2), 5, defaultContactMaterial, 0.02);
const sphereCenterBody = createSphereBody(world, 0.01, new CANNON.Vec3(0, 5, 0), 0, defaultContactMaterial, 0.02);


//orbit

function applyForceToSphere(sphereBody, centerBody, velocity, forceMultiplier) {
    sphereBody.velocity.copy(velocity);

    sphereBody.preStep = function () {
        const sphereToCenter = new CANNON.Vec3();
        centerBody.position.vsub(this.position, sphereToCenter);

        sphereToCenter.normalize();
        sphereToCenter.mult(forceMultiplier, this.force);
    };
}

const velocitySpeed = 0.2;

applyForceToSphere(sphereOneBody, sphereCenterBody, new CANNON.Vec3(velocitySpeed, 0, 0), 2.5);
applyForceToSphere(sphereTwoBody, sphereCenterBody, new CANNON.Vec3(0, velocitySpeed, 0), 2.5);
applyForceToSphere(sphereThreeBody, sphereCenterBody, new CANNON.Vec3(0, 0, velocitySpeed), 2.5);

// Constraint
const maxDistance = 0.80
const minDistance = 0.40

const sphereOneConstraint = new CANNON.DistanceConstraint(
    sphereCenterBody,
    sphereOneBody,
    minDistance,
    maxDistance
);
const sphereTwoConstraint = new CANNON.DistanceConstraint(
    sphereCenterBody,
    sphereTwoBody,
    minDistance,
    maxDistance
);
const sphereThreeConstraint = new CANNON.DistanceConstraint(
    sphereCenterBody,
    sphereThreeBody,
    minDistance,
    maxDistance
);

world.addConstraint(sphereOneConstraint);
world.addConstraint(sphereTwoConstraint);
world.addConstraint(sphereThreeConstraint);


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */

// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 19
camera.position.z = 0


camera.lookAt(new THREE.Vector3(0, 0, 0))
scene.add(camera)

/**
 * Mouse
 */

const mouse = new THREE.Vector2()

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.toneMappingExposure = 1.25;

/**
 * Generate galaxy
 */

generateGalaxy()

/**
 * Animate
 */
const clock = new THREE.Clock()

/******* NAVIGATION animation  ********/

//Start animation
gsap.delayedCall(2.5, () => {
    gsap.delayedCall(0, () => {
        gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 2, delay: 1, value: 0 })
    })
    gsap.to(camera.position, { duration: 1, delay: 2, y: 6.5, z: 0, x: 0, ease: 'power3.inOut' })
    canvas.classList.remove('clip-path-full')
    canvas.classList.remove('clip-path-none')
    gsap.to(loaderContainer, {
        opacity: 0,
        duration: 1,
        display: "none"
    })

    //Home navigation Animation
    gsap.to(containerSectionMaestrom, { duration: 2, delay: 3, display: 'block', opacity: 1 })
    //Display home section container
    gsap.delayedCall(0.25, () => {
        homeContainer.classList.add('is-active')
    })

})

/**
 * Navigation animation & Display section 
**/
//Container var
const homeContainer = document.querySelector('.home-container')
const aboutContainer = document.querySelector('.about-container')
const projectContainer = document.querySelector('.project-container')

//Function navigation
function homeNavigation() {
    canvas.classList.remove('clip-path-none')
    //Camera animation
    gsap.to(camera.position, { duration: 1, delay: 0.1, y: 6.5, z: 0, x: 0, ease: 'power3.inOut' })
    gsap.to(camera.rotation, { duration: 1.5, delay: 0.1, x: -1.6, z: 0, ease: 'power3.inOut' })
    //remove is-active from last section displayed
    if (aboutContainer.classList.contains('is-active') || projectContainer.classList.contains('is-active')) {
        aboutContainer.classList.remove('is-active')
        projectContainer.classList.remove('is-active')
    }
    //add is-active to the selected section
    gsap.delayedCall(0, () => {
        homeContainer.classList.add('is-active')
    })
    baselineHome.style.opacity = 1
}

function aboutNavigation() {
    //Camera animation
    gsap.to(camera.position, { duration: 1.5, delay: 0.1, y: 0, x: 0, z: 11, ease: 'power3.inOut' })
    gsap.to(camera.rotation, { duration: 1.5, delay: 0.1, x: 0, z: 0, ease: 'power3.inOut' })

    //remove is-active from last section displayed
    if (homeContainer.classList.contains('is-active') || projectContainer.classList.contains('is-active')) {
        homeContainer.classList.remove('is-active')
        projectContainer.classList.remove('is-active')
    }
    //Add is-active to the selected section
    gsap.delayedCall(0, () => {
        aboutContainer.classList.add('is-active')
    })

}

function projectNavigation() {
    //Camera animation
    gsap.to(camera.position, { duration: 1, delay: 0.1, y: 0.2, z: 0, x: 0, ease: 'power3.inOut' })
    gsap.to(camera.rotation, { duration: 1.5, delay: 0.1, x: -1.6, ease: 'power3.inOut' })

    //remove is-active from last section displayed
    if (homeContainer.classList.contains('is-active') || aboutContainer.classList.contains('is-active')) {
        homeContainer.classList.remove('is-active')
        aboutContainer.classList.remove('is-active')
    }
    //Add is-active to the selected section
    gsap.delayedCall(0, () => {
        projectContainer.classList.add('is-active')
    })
}

//Home
homeBtns.forEach(homeBtn => {
    homeBtn.addEventListener("click", () => {
        homeNavigation()
    })
})

aboutBtns.forEach(aboutBtn => {
    aboutBtn.addEventListener("click", () => {
        aboutNavigation()
        canvas.classList.add('clip-path-none')
    })
})

portBtns.forEach(portBtn => {
    portBtn.addEventListener("click", () => {
        projectNavigation()
        canvas.classList.add('clip-path-none')
    })
})

/**
 * Scroll
 */
function lerp(x, y, a) {
    return (1 - a) * x + a * y;
}

// Used to fit the lerps to start and end at specific scrolling percentages
function scalePercent(start, end) {
    return (scrollPercent - start) / (end - start);
}

//var for scrollAnim
//homevar
const homeTitleExperience = document.querySelectorAll('.span-title-part')
const homeTitleExperienceSpanLetters = document.querySelectorAll('.span-letter')
const baselineHome = document.querySelector('.home-container-section-baseline')
const interractiveBaselineHome = document.querySelector('.interractive-baseline')

//My array full of scroll animation
const animationArrayHome = [];
const animationArrayAbout = [];

animationArrayHome.push({
    start: 0,
    end: 30,
    func: function () {
        sphereCenterBody.position.y = lerp(5, 5.4, scalePercent(0, 30))
        sphereCenterBody.position.x = lerp(0, -0.3, scalePercent(0, 30))
    },
});

animationArrayHome.push({
    start: 30,
    end: 35,
    func: function () {
        interractiveBaselineHome.style.opacity = lerp(0, 1, scalePercent(30, 35))
    },
});

animationArrayHome.push({
    start: 25,
    end: 30,
    func: function () {
        gsap.to(homeTitleExperience, {
            y: "0",
            stagger: 0.1,
            ease: 'power1.inOut',
        })
        gsap.delayedCall(0.75, () => {
            gsap.to(homeTitleExperienceSpanLetters, {
                y: "0",
                stagger: 0.05,
                ease: 'power4.inOut',
                duration: 0.5,
            })
            gsap.delayedCall(1.2, () => {
                gsap.to(homeTitleExperienceSpanLetters, {
                    duration: 0.3,
                    marginRight: "1vw",
                    ease: 'power4.inOut',
                })
            })
        })
    },
});

animationArrayHome.push({
    start: 30,
    end: 40,
    func: function () {
        window.addEventListener('mousemove', (event) => {
            mouse.x = event.clientX / sizes.width * 2 - 1
            mouse.y = -(event.clientY / sizes.height * 2 - 1)
        })
    }
})

animationArrayHome.push({
    start: 30,
    end: 37,
    func: function () {
        baselineHome.style.opacity = lerp(1, 0, scalePercent(30, 37))
    },
});

animationArrayHome.push({
    start: 66,
    end: 73,
    func: function () {
        interractiveBaselineHome.style.opacity = lerp(1, 0, scalePercent(66, 73))
    },
});

animationArrayHome.push({
    start: 0,
    end: 80,
    func: function () {
        if (!homeContainer.classList.contains('is-active'))
            return
        canvas.classList.remove('clip-path-none')
        if (homeContainer.classList.contains('is-active'))
            return
        canvas.classList.add('clip-path-none')
    },
});

animationArrayHome.push({
    start: 80,
    end: 100,
    func: function () {
        canvas.classList.add('clip-path-none')
    },
});

//Array About scroll animation

const textPhilo = document.querySelector("#deux");

textPhilo.innerHTML = textPhilo.textContent
    .split(/(\b|\s)/) // Utilisez une expression régulière pour conserver les espaces
    .map(word => {
        if (word.trim() === '') {
            return word; // Garde les espaces tels quels
        }

        return `<span class="word">` +
            word.split('').map(char => {
                return `<span class='letter'>${char}</span>`;
            }).join('') +
            `</span>`;
    }).join('');

const letterPhilo = document.querySelectorAll('.letter')
const titlePhilo = document.querySelector('.title-philo')

animationArrayAbout.push({
    start: 5,
    end: 10,
    func: function () {
        gsap.to(camera.rotation, {
            z:"0",
            duration: 0.8,
            ease: 'power3.inOut',
        })
    },
});

animationArrayAbout.push({
    start: 15,
    end: 20,
    func: function () {
        gsap.to(camera.rotation, {
            z:"1.57",
            duration: 0.8,
            ease: 'power3.inOut',
        })
    },
});

animationArrayAbout.push({
    start: 40,
    end: 50,
    func: function () {
        gsap.to(titlePhilo, {
            y: "0",
            stagger: 0.1,
            ease: 'power1.inOut',
        })
    },
});

animationArrayAbout.push({
    start: 50,
    end: 60,
    func: function () {
        gsap.to(letterPhilo, {
            y: "0",
            stagger: 0.01,
            ease: 'power1.inOut',
        })
    },
});

function playScrollAnimations() {
    if (camera.position.y === 6.5) {
        animationArrayHome.forEach(function (a) {
            if (scrollPercent >= a.start && scrollPercent < a.end) {
                a.func();
            }
        });
    }
    if (camera.position.z === 11) {
        animationArrayAbout.forEach(function (a) {
            if (scrollPercent >= a.start && scrollPercent < a.end) {
                a.func()
            }
        })
    }
}

let previousTime = 0
const tick = () => {
    // console.log(scrollPercent);
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    //Update Material
    material.uniforms.uTime.value = elapsedTime

    //Sphere rotation
    sphereOne.rotation.z = elapsedTime * 1
    sphereOne.rotation.x = elapsedTime * 0.2
    sphereTwo.rotation.z = elapsedTime * 1
    sphereTwo.rotation.x = elapsedTime * 0.15
    sphereThree.rotation.z = elapsedTime * 1
    sphereThree.rotation.x = elapsedTime * 0.25

    //Animate Camera on mousevent
    const parallaxX = cursor.x
    points.position.x += (parallaxX - points.position.x) * 5 * deltaTime
    const parallaxY = cursor.y
    points.position.y += (parallaxY - points.position.y) * 5 * deltaTime

    //Animate sphere on scroll
    playScrollAnimations();

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

    //Physics 
    world.step(1 / 60, deltaTime, 3)
    sphereOne.position.copy(sphereOneBody.position)
    sphereTwo.position.copy(sphereTwoBody.position)
    sphereThree.position.copy(sphereThreeBody.position)

    sphereCenterBody.position.x += (mouse.x - sphereCenterBody.position.x) * 0.5 * deltaTime

    LightSphere.position.copy(sphereCenterBody.position)
}

tick()